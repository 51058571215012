import { PortfolioItemDataWithComponent } from "data/portfolio/types";
import ViewPortfolioAist from "../../components/views/ViewPortfolioItem/ViewPortfolioAist";
import {buildPublicImageSrc} from "../../helpers/path";

const porfolioItem: PortfolioItemDataWithComponent = {
    id: "aist",
    component: ViewPortfolioAist,
    title: "aist",
    name: "АИСТ24 - логистика",
    altName: "Автоматизированная \n информационная система \n транспорта",
    year: 2023,
    type: "design",
    subtype: "portal",

    preview: {
        label: "портал",
        media: {
            type: 'image',
            fromSrc: buildPublicImageSrc({
                path: '',
                file: '',
            }),
            toSrc: buildPublicImageSrc({
                path: '',
                file: '',
            }),
        },
    },

    socials: [
        {
            label: "behance",
            href: "https://www.behance.net/gallery/169647361/UX-case-Aist24",
        },
    ],

    poolOfWorks: [
        "UX-research",
        "Job-stories",
        "Cjm",
        "Wireframing"
    ],


    description: [
        "Это ІТ-платформа для автоматизации перевозок сельскохозяйственной продукции.",
        "Конечным продуктом данной компании является CRM-сервис с возможностью размещения заказов на бирже от клиента и полным регулированием поездок водителей по заявкам."
    ],
    shortDescription: "Разработка сайта для необанка из Великобритании",
    seo: {
        title: "Разработка сайта для необанка из Великобритании CFS",
        description: "Создали дизайн и разработали корпоративный сайт для приложения онлайн банка в Великобритании, который включает в себя детальное описание продукта компании, раздел с информацией о технической поддержке, вакансии, новости и статьи",
        keywords: ["разработка сайта, дизайн сайта, разработка сайта стартапа, сайт онлайн банка, необанк, нео банк, корпоративный сайт банка"],
        openGraph: {
            type: "article",
            title: "Разработка сайта для необанка из Великобритании CFS",
            description: "Создали дизайн и разработали корпоративный сайт для приложения онлайн банка в Великобритании, который включает в себя детальное описание продукта компании, раздел с информацией о технической поддержке, вакансии, новости и статьи",
        }
    },
};

export default porfolioItem;
