import ViewPortfolioSk10Site from "components/views/ViewPortfolioItem/ViewPortfolioSk10Site/";
import { PortfolioItemDataWithComponent } from 'data/portfolio/types';

const porfolioItem: PortfolioItemDataWithComponent = {
  id: 'sk-10',
  component: ViewPortfolioSk10Site,
  title: 'sk10',
  name: 'sk-10',
  altName: 'construction company',
  year: 2022,
  awardsList: [],

  link: {
    label: 'sk10.ru',
    href: 'https://sk10.ru/',
  },

  socials: [
    {
      label: 'behance',
      href: 'https://www.behance.net/gallery/193024329/SK10-Construction-company-Real-estate-developer',
    },
  ],

  preview: {
    label: 'corporate site',
    backgroundColor: '#1F1F21',
    media: {
      type: 'video',
      src: 'https://vimeo.com/996100103/c60bc20173',
    },
  },

  poolOfWorks: ["Research", 'UX|UI design', "refinements-of-the-design-system"],

  type: 'site',
  subtype: 'site',

  description: [
    'company engaged in the construction of residential spaces. Our task was to make a technical requirement for in-house work with hi-fi wireframes, create the logic of interaction with the user interface',
  ],
  shortDescription: 'website for the construction company',

  seo: {
    title: "Website Development for the No. 1 Developer SK10 | Case by SAGIROV.com Studio",
    description: "Learn how the SAGIROV.com studio developed a modern and functional website for the developer SK10. A complete solution for showcasing residential complexes, increasing conversions, and enhancing user experience, including the implementation of online mortgage application functionality and apartment purchases through the website.",
    keywords: [
      "website development for developers",
      "SK10 case",
      "website creation for developers",
      "web design residential complexes",
      "construction company website",
    ],
    openGraph: {
      type: "article",
      title: "Website Development for the No. 1 Developer SK10 | Case by SAGIROV.com Studio",
      description: "Learn how the SAGIROV.com studio developed a modern and functional website for the developer SK10. A complete solution for showcasing residential complexes, increasing conversions, and enhancing user experience, including the implementation of online mortgage application functionality and apartment purchases through the website.",
    },
  },
};

export default porfolioItem;
