import List from 'components/common/List';
import { useElemRef } from 'hooks/useElemRef';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import gsap from 'gsap';
import RevealWhenInView from 'components/common/RevealInView/RevealWhenInView';
import { DynamicBackgroundTrigger } from 'components/common/DynamicBackground';
import { useViewPortfolioItemContext } from './ViewPortfolioItem';
import Print from 'components/common/Print';
import { MorphSvgWord } from 'components/common/MorphSvg';
import ScrollDownButton from 'components/layouts/ScrollDownButton';
import Section from 'components/layouts/Section';

interface ViewPortfolioItemLeadSectionProps {}

const ViewPortfolioItemLeadSection = (
  props: ViewPortfolioItemLeadSectionProps
) => {
  const [morph, setMorph] = useState(false);

  const { data } = useViewPortfolioItemContext();

  const { elemRef, setElemRef } = useElemRef({
    name: null,
    yearTop: null,
    awards: null,
    yearBottom: null,
    yearMiddle: null,
    link: null,

    socialsTop: null,
    socialsBottom: null,

    companyName: null,
    arrow: null,

    worksDoneLabel: null,
    worksDoneList: null,
  });

  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      setMorph(false);
    });
  }, [history]);

  useEffect(() => {
    const timeline = gsap.timeline({ delay: 0.7, paused: true });
    const animateElem = elemRef.current;
    const tweenDefaults = {
      duration: 0.6,
      opacity: 0,
      ease: 'ease-out',
      y: 20,
    };

    const tweensStagger = tweenDefaults.duration - 0.1;
    const staggerPosition = `-=${tweensStagger}`;

    const isMinMd = matchMedia('(min-width: 768px)').matches;
    const isMinxl = matchMedia('(min-width: 1440px)').matches;

    const showNameTween = gsap.from(animateElem.name, {
      ...tweenDefaults,
    });

    const showYearTopTween = gsap.from(animateElem.yearTop, {
      ...tweenDefaults,
    });

    const showAwardsTween = gsap.from(animateElem.awards, {
      ...tweenDefaults,
    });

    const showWorksDoneLabelTween = gsap.from(animateElem.worksDoneLabel, {
      ...tweenDefaults,
    });
    const showWorksDoneListTween = gsap.from(animateElem.worksDoneList, {
      ...tweenDefaults,
    });

    const showLinkTween = gsap.from(animateElem.link, {
      ...tweenDefaults,
    });

    const showSocialsTopTween = gsap.from(animateElem.socialsTop, {
      ...tweenDefaults,
    });

    const showCompanyNameTween = gsap.from(animateElem.companyName, {
      ...tweenDefaults,
    });

    const showArrowTween = gsap.from(animateElem.arrow, {
      ...tweenDefaults,
      y: -20,
    });

    const showSocialsBottomTween = gsap.from(animateElem.socialsBottom, {
      ...tweenDefaults,
    });

    const showYearMiddleTween = gsap.from(animateElem.yearBottom, {
      ...tweenDefaults,
    });

    if (isMinxl) {
      timeline.add(showNameTween);
      timeline.add(showYearTopTween, '<');
      timeline.add(showAwardsTween, '<');

      timeline.add(showLinkTween, staggerPosition);
      timeline.add(showWorksDoneLabelTween, '<');
      timeline.add(showWorksDoneListTween, '<');

      timeline.add(showCompanyNameTween, staggerPosition);
      timeline.add(showSocialsBottomTween, '<');

      timeline.add(showArrowTween, '<');
    } else if (isMinMd) {
      timeline.add(showNameTween);

      timeline.add(
        [showLinkTween, showWorksDoneLabelTween, showWorksDoneListTween],
        staggerPosition
      );

      // timeline.add(showLinkTween, staggerPosition);
      // timeline.add(showWorksDoneLabelTween, "<");
      // timeline.add(showWorksDoneListTween, "<");

      timeline.add(showCompanyNameTween, staggerPosition);
      timeline.add(showSocialsBottomTween, '<');

      timeline.add(showYearMiddleTween, staggerPosition);
      timeline.add(showArrowTween, '<');
    } else {
      timeline.add(showNameTween);

      timeline.add(showWorksDoneLabelTween, staggerPosition);
      timeline.add(showLinkTween, '<');

      timeline.add(showSocialsTopTween, staggerPosition);
      timeline.add(showWorksDoneListTween, '<');

      timeline.add(showYearMiddleTween, staggerPosition);

      timeline.add(showCompanyNameTween, staggerPosition);
      timeline.add(showArrowTween, '<');
    }

    timeline.add(() => setMorph(true));
    timeline.play();

    return () => {
      timeline.kill();
    };
  }, [elemRef, data]);

  return (
    <DynamicBackgroundTrigger>
      <Section
        isLead
        viewportHeight
        className="view-portfolio-item-lead-section">
        <div className="view-portfolio-item-lead-section__container container lead-section__container">
          <div className="view-portfolio-item-lead-section__inner lead-section__inner">
            <header className="view-portfolio-item-lead-section__header row">
              <div className="view-portfolio-item-lead-section__header-col-with-site-name">
                <div ref={setElemRef('name')}>
                  <h1 className="u-sr-only">
                    <Print data={data.name} />
                  </h1>

                  <RevealWhenInView>
                    <MorphSvgWord
                      className="svg-title"
                      word={data.title}
                      morph={morph}
                    />
                  </RevealWhenInView>
                </div>
              </div>
              <div className="view-portfolio-item-lead-section__header-col-with-year">
                <p ref={setElemRef('yearTop')} className="label">
                  <RevealWhenInView tag="span">
                    <span className="side-brackets">{data.year}</span>
                  </RevealWhenInView>
                </p>
              </div>
              {data.awardsList && (
                <div className="awards" ref={setElemRef('awards')}>
                  {data.awardsList.map((awards, index) => (
                    <RevealWhenInView key={index}>
                      <div className="awards-item">
                        <img
                          className="awards-img"
                          src={awards.imgSrc}
                          width="210"
                          height="210"
                        />
                      </div>
                    </RevealWhenInView>
                  ))}
                </div>
              )}
            </header>
            <div className="view-portfolio-item-lead-section__body lead-section__body">
              <div className="view-portfolio-item-lead-section__body-top">
                <div className="view-portfolio-item-lead-section__body-top-inner row">
                  <div className="view-portfolio-item-lead-section__body-top-col-with-site-link col-7 col-md-5 col-xl-4">
                    {data.link && (
                      <a
                        ref={setElemRef('link')}
                        className="link link--normal label"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          typeof data.link === 'object'
                            ? data.link.href
                            : data.link
                        }>
                        <RevealWhenInView>
                          {typeof data.link === 'object'
                            ? data.link.label
                            : data.link}
                        </RevealWhenInView>
                      </a>
                    )}
                    {data.text && (
                      <p className="label">
                        <RevealWhenInView>{data.text}</RevealWhenInView>
                      </p>
                    )}
                    {data.vc && (
                      <div className="label-vc">
                        <RevealWhenInView>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M4 14H11V12H4V14ZM4 10H14V8H4V10ZM4 6H14V4H4V6ZM0 18V0H18V18H0ZM2 16H16V2H2V16Z"
                              fill="#1F1F21"
                            />
                          </svg>
                        </RevealWhenInView>
                        <a
                          ref={setElemRef('link')}
                          className="link link--normal label"
                          target="_blank"
                          rel="noreferrer nofollow"
                          href={
                            typeof data.vc === 'object' ? data.vc.href : data.vc
                          }>
                          <RevealWhenInView>
                            {typeof data.vc === 'object'
                              ? data.vc.label
                              : data.vc}
                          </RevealWhenInView>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="view-portfolio-item-lead-section__body-top-col-with-works-done-label col-4 col-md-2 col-xl-1">
                    <p ref={setElemRef('worksDoneLabel')} className="label">
                      <RevealWhenInView tag="span">
                        <span className="side-brackets">
                          <Print data="Pool of works" />
                        </span>
                      </RevealWhenInView>
                    </p>
                  </div>
                  <div className="view-portfolio-item-lead-section__body-top-col-with-behance-link col-7">
                    <List items={data.socials}>
                      {({ label, href }) => (
                        <a
                          ref={setElemRef('socialsTop')}
                          className="link link--normal label"
                          href={href}
                          target="_blank"
                          rel="noreferrer nofollow">
                          <RevealWhenInView>{label}</RevealWhenInView>
                        </a>
                      )}
                    </List>
                    {data.vc && (
                      <div className="label-vc">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4 14H11V12H4V14ZM4 10H14V8H4V10ZM4 6H14V4H4V6ZM0 18V0H18V18H0ZM2 16H16V2H2V16Z"
                            fill="#1F1F21"
                          />
                        </svg>
                        <a
                          ref={setElemRef('link')}
                          className="link link--normal label"
                          target="_blank"
                          rel="noreferrer nofollow"
                          href={
                            typeof data.vc === 'object' ? data.vc.href : data.vc
                          }>
                          <RevealWhenInView>
                            {typeof data.vc === 'object'
                              ? data.vc.label
                              : data.vc}
                          </RevealWhenInView>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="view-portfolio-item-lead-section__body-top-col-with-works-done-list col-4 col-md col-xl-auto">
                    <RevealWhenInView>
                      <List
                        ref={setElemRef('worksDoneList')}
                        items={data.poolOfWorks}
                        itemClassName="label">
                        {(item) => (
                          <Print
                            data={`view:portfolioItem.lead.poolOfWorks.item.${item}`}
                          />
                        )}
                      </List>
                    </RevealWhenInView>
                  </div>
                </div>
              </div>
              <div className="view-portfolio-item-lead-section__body-bottom">
                <div className="view-portfolio-item-lead-section__body-bottom-inner row">
                  <div className="view-portfolio-item-lead-section__body-bottom-col-with-behance-link order-md-2 col-md-4 offset-md-2 col-xl-auto offset-xl-1">
                    <List items={data.socials}>
                      {({ label, href }) => (
                        <a
                          ref={setElemRef('socialsBottom')}
                          className="link link--normal label"
                          href={href}
                          target="_blank"
                          rel="noreferrer nofollow">
                          <RevealWhenInView>{label}</RevealWhenInView>
                        </a>
                      )}
                    </List>
                  </div>
                  <div className="view-portfolio-item-lead-section__body-bottom-col-with-year offset-7 col-4 order-xl-2 order-md-0 offset-md-0 col-md-5">
                    <p ref={setElemRef('yearBottom')} className="label">
                      <RevealWhenInView tag="span">
                        <span className="side-brackets">{data.year}</span>
                      </RevealWhenInView>
                    </p>
                  </div>
                  <div className="view-portfolio-item-lead-section__body-bottom-col-with-company-name col order-xl-1 order-md-2 col-md-5 col-xl-4">
                    <p
                      ref={setElemRef('companyName')}
                      className="footnote lead-section__footnote text--pre-line">
                      <RevealWhenInView tag="span">
                        {data.altName}
                      </RevealWhenInView>
                    </p>
                  </div>
                  <div className="view-portfolio-item-lead-section__body-bottom-col-with-arrow col-4 order-md-3 col-md-auto">
                    <RevealWhenInView from={{ y: -20, opacity: 0 }}>
                      <ScrollDownButton scrollTo=".view-portfolio-item-about-section" />
                    </RevealWhenInView>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </DynamicBackgroundTrigger>
  );
};

export default ViewPortfolioItemLeadSection;
