import menu from "./menu.json";
import menuEn from "./menu-en.json";
import vacancies from "./vacancies.json";
import vacanciesEn from "./vacancies-en.json";
import works from "./works.json";
import worksEn from "./works-en.json";
import discuss from "./discuss.json";
import discussEn from "./discuss-en.json";
import sagirovDotCom from "./sagirov.com.json";
import skess from "./skess.json";
import skessEn from "./skess-en.json";
import services from "./services.json";
import servicesEn from "./services-en.json";
import rost from "./rost.json";
import rostEn from "./rost-en.json";
import gosapteka from "./gosapteka.json";
import gosaptekaEn from "./gosapteka-en.json";
import domaVeka from "./doma-veka.json";
import domaVekaEn from "./doma-veka-en.json";
import atlantic from "./atlantic.json";
import atlanticEn from "./atlantic-en.json";
import novaGroup from "./nova-group.json";
import womanSport from "./woman-sport.json";
import sites from "./sites.json";
import sitesEn from "./sites-en.json";
import mobile from "./mobile.json";
import mobileEn from "./mobile-en.json";
import branding from "./branding.json";
import brandingEn from "./branding-en.json";
import wideDash from "./wide-dash.json";
import globeIt from "./globe-it.json";
import ensemble from "./ensemble.json";
import icr from "./icr.json";
import csd from "./csd.json";
import mumu from "./mumu.json";
import mumuEn from "./mumuEn.json";
import design from "./design.json";
import development from "./development.json";
import wildberries from "./wildberries.json";
import wildberriesEn from "./wildberries-en.json";
import opzero from "./opzero.json";
import mynaret from "./mynaret.json";
import sk10 from "./sk10.json";
import rutube from "./rutube.json";
import сfps from "./сfps.json";
import aist from "./aist.json";
import developerSites from "./developer-sites.json";
import cryptoro from "./cryptoro.json";

import { PortfolioItemTitlesType } from "data/portfolio/types";

interface Word {
  width: number;
  height: number;
  letters: {
    name?: string;
    path:
      | {
          from: string;
          to: string;
        }
      | string;
  }[];
}

export type WordsType =
  | "wildberriesEn"
  | "wildberries"
  | "menu"
  | "menuEn"
  | "vacancies"
  | "vacanciesEn"
  | "works"
  | "worksEn"
  | "discuss"
  | "discussEn"
  | "sagirov.com"
  | "services"
  | "servicesEn"
  | "sites"
  | "sitesEn"
  | "mobile"
  | "mobileEn"
  | "branding"
  | "brandingEn"
  | "-"
  | "design"
  | "development"
  | "developer-sites"
  | PortfolioItemTitlesType;

const words: {
  [key in WordsType]: Word;
} = {
  menu,
  menuEn,
  vacancies,
  vacanciesEn,
  works,
  worksEn,
  discuss,
  discussEn,
  "sagirov.com": sagirovDotCom,
  skess,
  services,
  servicesEn,
  rost,
  gosapteka,
  atlantic,
  sites,
  sitesEn,
  branding,
  brandingEn,
  mobile,
  mobileEn,
  "-": wideDash,
  "nova-group": novaGroup,
  "doma-veka": domaVeka,
  "woman-sport": womanSport,
  "globe-it": globeIt,
  ensemble,
  skessEn,
  gosaptekaEn,
  "doma-veka-en": domaVekaEn,
  rostEn,
  atlanticEn,
  icr,
  csd,
  mumu,
  mumuEn,
  wildberries,
  wildberriesEn,
  opzero,
  design,
  mynaret,
  development,
  sk10,
  rutube,
  сfps,
  aist,
  "developer-sites": developerSites,
  cryptoro
};

export default words;
